$('.navbar-nav>li>a').on('click', function () {
  if ($(this).attr('data-scroll-nav')) {
    $('.navbar-collapse').collapse('hide');
  }
});
$('.navbar-brand').on('click', function () {
  if ($(this).attr('data-scroll-nav')) {
    $('.navbar-collapse').collapse('hide');
  }
});
/*$(document).ready(function() {
    $('.character-more-info').click(function () {
        if ($(this).hasClass('active')) {
            $('.characters-area').removeClass('active');
            $(this).removeClass('active');
        } else {
            $('.characters-area').addClass('active');
            let characterId = $(this).data('show-description');
            $('.character-more-info').removeClass('active');
            $(this).addClass('active');
            $('.character-description').removeClass('active');
            $(`#${characterId}`).addClass('active');
        }
    });
});*/
