$("#contact-form").on('submit', function (event) {
  event.preventDefault();
  let url = $(this).attr('action');
  let name = $("input[name=name]").val();
  let email = $("input[name=email]").val();
  let mensagem = $("textarea[name=mensagem]").val();
  let subject = $("select[name=subject]").val();
  let _token = $('meta[name="csrf-token"]').attr('content');

  $.ajax({
    url,
    type: "POST",
    data: {
      name,
      email,
      mensagem,
      subject,
      _token
    },
    success: function (response) {
      alert('Enviado com sucesso');
      console.log(response);
      $('#contact-form').trigger("reset");
    },
  });
});
