const characters = document.querySelectorAll('.character-area');
const charactersContainer = document.querySelector('.characters-container');
let scrollLeftDiference = 0;
let character = 1;
let activeDescription = 0;

let timeout;

let runSlide = true;

function nextChar() {
  runSlide = false;
  clearTimeout(timeout);
  nextCharacter();
  runSlide = true;
  slide();
}

function nextCharacter() {
  if (character == 11) return;
  const scrollLeftTotal = charactersContainer.scrollWidth - charactersContainer.clientWidth;
  $('.character-description').hide();
  $('.character-arrow').hide();
  let scrollValue = charactersContainer.scrollLeft + characters[character++].offsetWidth;
  if (scrollValue > scrollLeftTotal) {
    // console.log(`Total: ${charactersContainer.scrollLeft - scrollLeftTotal}`);
    scrollLeftDiference = charactersContainer.scrollLeft - scrollLeftTotal;
    // charactersContainer.scrollLeft = scrollLeftTotal;
    $('.characters-container').animate({
      scrollLeft: scrollLeftTotal,
    }, 500);
  } else {
    // console.log(scrollValue);
    // charactersContainer.scrollLeft = scrollValue;
    $('.characters-container').animate({
      scrollLeft: scrollValue,
    }, 500);
  }
}

function prevChar() {
  runSlide = false;
  clearTimeout(timeout);
  prevCharacter();
  runSlide = true;
  slide();
}

function prevCharacter() {
  if (character == 1) return;
  $('.character-description').hide();
  $('.character-arrow').hide();
  const scrollLeftTotal = charactersContainer.scrollWidth - charactersContainer.clientWidth;
  let scrollValue = charactersContainer.scrollLeft - characters[--character].offsetWidth;
  if (charactersContainer.scrollLeft === scrollLeftTotal) {
    // charactersContainer.scrollLeft += scrollLeftDiference;
    // console.log(charactersContainer.scrollLeft + scrollLeftDiference);
    $('.characters-container').animate({
      scrollLeft: charactersContainer.scrollLeft + scrollLeftDiference,
    }, 500);
  }
  else {
    // console.log(scrollValue);
    // charactersContainer.scrollLeft = scrollValue;
    $('.characters-container').animate({
      scrollLeft: scrollValue,
    }, 500);
  }
}

function slide() {
  const scrollLeftTotal = charactersContainer.scrollWidth - charactersContainer.clientWidth;
  if (runSlide) {
    timeout = setTimeout(() => {
      if (charactersContainer.scrollLeft === scrollLeftTotal) {
        character = 0;
        $('.characters-container').animate({
          scrollLeft: 0,
        }, 500);
      } else {
        nextCharacter();
      }
      slide();
    }, 4000)
  }
}

function showDescription(id, button) {
  $('.gift-tabs').on('click', function (e) {
    $('.gift-tabs').removeClass('active');
    $(this).addClass('active');

    let container = $(this).parent().find('.gifts');
    console.log(container);
    let selectedTab = $(this).data('gift-tab');
    container.find('.gift').hide();
    container.find(`.gift-${selectedTab}`).show();
  });

  $('.gift-tabs').first().click();

  if (activeDescription === id) {
    $('.character-arrow').hide();
    $('.character-description').hide();
    $(button).removeClass('active');
    activeDescription = 0;
    runSlide = true;
    slide();
  } else {
    activeDescription = id;
    runSlide = false;
    clearTimeout(timeout);
    $('.character-description').hide();
    $(`#character-description-${id}`).show();
    $('.character-arrow').hide();
    $(`#character-${id}-arrow`).show();
    $('.character-area button.active').removeClass('active');
    $(button).addClass('active');
  }
}

$(document).ready(function () {
  slide();
});
