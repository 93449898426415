let counter = 5;
let timeOut;
$('a').on('click', function (e) {
    const href = $(this).attr('href');
    const sameSite = href.includes('http://sanrio.concepttreinamentos.com.br');
    const isSite = href.includes('http');
    if (!sameSite && isSite) {
      e.preventDefault();
      //$('#redirect-url').text(href);
      $('#redirect-button').data('href', href);
      $('#redirect-modal').modal('toggle');
      redirectTimeOut();
    }
});

$('#redirect-button').on('click', function (e) {
  clearTimeout(timeOut);
  counter = 5;
  const href = $(this).data('href');
  const modalIsOpen = ($("#redirect-modal").data('bs.modal') || {})._isShown;
  if (modalIsOpen) {
    $('#redirect-modal').modal('toggle');
  }
  window.open(href);
});

$('#cancel-redirect-button').on('click', function (e) {
    cancelRedirect();
});

$('#redirect-modal').on('click', function (e) {
    cancelRedirect();
});


function cancelRedirect() {
    clearTimeout(timeOut);
    counter = 5;
    const modalIsOpen = ($("#redirect-modal").data('bs.modal') || {})._isShown;
    if (modalIsOpen) {
      $('#redirect-modal').modal('toggle');
    }
}
function redirectTimeOut() {
  $('#redirect-timeout').text(counter);
  if (counter == 0) {
    const modalIsOpen = ($("#redirect-modal").data('bs.modal') || {})._isShown;
    const href = $('#redirect-button').data('href');
    window.open(href);
    if (modalIsOpen) {
      $('#redirect-modal').modal('toggle');
    }
    counter = 5;
    return;
  }
  counter--;
  timeOut = setTimeout(() => {
    redirectTimeOut();
  }, 1000);
}
